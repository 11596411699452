<script setup lang="ts">
import type { EventCard } from "~/types/events";

const props = defineProps<{
  event: EventCard;
  weekday?: Intl.DateTimeFormatOptions["weekday"];
}>();

const length = computed(() => props.event.dates?.length ?? 0);

const monthStyle = computed(() => (length.value > 1 ? "numeric" : "long"));
</script>

<template>
  <CardsEventInfoCell v-if="length" icon="calendar-day">
    <div class="event-dates">
      <time
        v-for="date in event.dates"
        :key="date.getTime()"
        :datetime="date.toDateString()"
        class="event-time"
      >
        {{
          date.toLocaleDateString("no-NO", {
            weekday,
            day: "numeric",
            month: monthStyle,
            year: "numeric",
          })
        }}
      </time>
    </div>
  </CardsEventInfoCell>
</template>

<style scoped lang="scss">
.event-dates {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  .event-time:not(:last-of-type)::after {
    content: ", ";
  }

  .event-time:first-of-type:first-letter {
    text-transform: capitalize;
  }
}
</style>
